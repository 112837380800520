import { GroupuiHeadline, GroupuiText } from '@group-ui/group-ui-react';
import useArabic from '../hooks/useArabic';
import useText from '../hooks/useText';
import parse from 'html-react-parser';
export default function Page(){
    const arabic = useArabic();
    const text = useText();
	return (
		<div className="page">
            <div className="ui-banner">
                <div className="media">
                    <video src={`${process.env.PUBLIC_URL}/assets/video/VWG_Mountains_green_blue.mp4`} autoPlay muted loop playsInline/>
                </div>
                <div className="ui-inner">
                    <GroupuiHeadline heading="h2" fontVariant="TheGroupHEAD-Light" marketing={true}>{parse(text.home_banner_title)}</GroupuiHeadline>
                    <GroupuiText>{text.home_banner_subtitle}</GroupuiText>
                </div>
            </div>

            <div className={`ui-content-teaser no-margin`}>
                <div className="ui-inner">
                    <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>{text.home_explore_title}</GroupuiHeadline>
                </div>
            </div>
            
            <div className={`ui-content-teaser no-gap-top`}>
                <div className="ui-inner">
                    <div className="ui-columns">
                        <div className="ui-column">
                            <div className="media">
                                <img src={`${process.env.PUBLIC_URL}/assets/img/editorial/about.jpg?123`} alt=""/>
                            </div>
                        </div>
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>{text.home_about_title}</GroupuiHeadline>
                            <GroupuiText>{text.home_about_description}</GroupuiText>
                            <a href={`/${arabic ? 'ar':'en'}/group/about`} className="cta">{text.find_out_more}</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`ui-content-teaser no-gap-top`}>
                <div className="ui-inner">
                    <div className="ui-columns">
                        <div className="ui-column">
                            <div className="media">
                                <img src={`${process.env.PUBLIC_URL}/assets/img/editorial/team.jpg?123`} alt=""/>
                            </div>
                        </div>
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>{text.home_team_title}</GroupuiHeadline>
                            <GroupuiText>{text.home_team_description}</GroupuiText>
                            <a href={`/${arabic ? 'ar':'en'}/group/team`} className="cta">{text.find_out_more}</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`ui-content-teaser no-gap-top`}>
                <div className="ui-inner">
                    <div className="ui-columns">
                        <div className="ui-column">
                            <div className="media">
                                <img src={`${process.env.PUBLIC_URL}/assets/img/editorial/ci/1.jpg?123`} alt=""/>
                            </div>
                        </div>
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>{text.home_strategy_title}</GroupuiHeadline>
                            <GroupuiText>{text.home_strategy_description}</GroupuiText>
                            <a href={`/${arabic ? 'ar':'en'}/group/strategy`} className="cta">{text.find_out_more}</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`ui-content-teaser no-gap-top`}>
                <div className="ui-inner">
                    <div className="ui-columns">
                        <div className="ui-column">
                            <div className="media">
                                <img src={`${process.env.PUBLIC_URL}/assets/img/fleet-banner.jpg?123`} alt=""/>
                            </div>
                        </div>
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>{text.home_fleet_title}</GroupuiHeadline>
                            <GroupuiText>{text.home_fleet_description}</GroupuiText>
                            <a href={`/${arabic ? 'ar':'en'}/group/fleet`} className="cta">{text.find_out_more}</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`ui-content-teaser no-gap-top`}>
                <div className="ui-inner">
                    <div className="ui-columns">
                        <div className="ui-column">
                            <div className="media">
                                <img src={`${process.env.PUBLIC_URL}/assets/img/editorial/ci/5.jpg?123`} alt=""/>
                            </div>
                        </div>
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>{text.home_integrity_title}</GroupuiHeadline>
                            <GroupuiText>{text.home_integrity_description}</GroupuiText>
                            <a href={`/${arabic ? 'ar':'en'}/group/integrity`} className="cta">{text.find_out_more}</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`ui-content-teaser no-gap-top`}>
                <div className="ui-inner">
                    <div className="ui-columns">
                        <div className="ui-column">
                            <div className="media">
                                <img src={`${process.env.PUBLIC_URL}/assets/img/editorial/conduct.jpg?123`} alt=""/>
                            </div>
                        </div>
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>{text.home_conduct_title}</GroupuiHeadline>
                            <GroupuiText>{text.home_conduct_description}</GroupuiText>
                            <a href={`/${arabic ? 'ar':'en'}/group/conduct`} className="cta">{text.find_out_more}</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`ui-content-teaser no-gap-top`}>
                <div className="ui-inner">
                    <div className="ui-columns">
                        <div className="ui-column">
                            <div className="media">
                                <img src={`${process.env.PUBLIC_URL}/assets/img/editorial/ci/2.jpg?123`} alt=""/>
                            </div>
                        </div>
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>{text.home_whistleblower_title}</GroupuiHeadline>
                            <GroupuiText>{text.home_whistleblower_description}</GroupuiText>
                            <a href={`/${arabic ? 'ar':'en'}/group/whistleblower`} className="cta">{text.find_out_more}</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`ui-content-teaser no-gap-top`}>
                <div className="ui-inner">
                    <div className="ui-columns">
                        <div className="ui-column">
                            <div className="media">
                                <img src={`${process.env.PUBLIC_URL}/assets/img/editorial/ci/3.jpg?123`} alt=""/>
                            </div>
                        </div>
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>{text.home_human_rights_title}</GroupuiHeadline>
                            <GroupuiText>{text.home_human_rights_description}</GroupuiText>
                            <a href={`/${arabic ? 'ar':'en'}/group/human-rights`} className="cta">{text.find_out_more}</a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
	);
}
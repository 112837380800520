import useText from '../hooks/useText';
import { GroupuiHeadline, GroupuiText, GroupuiAccordion } from '@group-ui/group-ui-react';
import parse from 'html-react-parser';

export default function Page(){
    const text = useText();
	return (
		<div className="page">
            {/* banner */}
            <div className="ui-banner">
                <div className="media">
                    <img src={`${process.env.PUBLIC_URL}/assets/img/careers/banner/working-with-us.jpg`} alt=""/>
                </div>
                <div className="ui-inner">
                    <GroupuiHeadline heading="h2" fontVariant="TheGroupHEAD-Light" marketing={true}>{parse(text.working1)}</GroupuiHeadline>
                    <GroupuiText>{parse(text.working2)}</GroupuiText>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser">
                <div className="ui-inner">
                    <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>{parse(text.working3)}</GroupuiHeadline>
                    <div className="ui-columns">
                        <div className="ui-column">
                            <div className="media">
                                <img src={`${process.env.PUBLIC_URL}/assets/img/careers/lead.jpg`} alt=""/>
                            </div>
                        </div>
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>{parse(text.working4)}</GroupuiHeadline>
                            <GroupuiText>{parse(text.working5)}</GroupuiText>
                            <a href="https://careers.volkswagen-me.info/index.php/job-listings" target="_blank" rel="noreferrer" className="cta">{parse(text.working6)}</a>
                        </div>
                    </div>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser ui-accordion ui-deep-blue-bg">
                <div className="ui-inner">
                    <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>{parse(text.working7)}</GroupuiHeadline>
                    <div className="ui-columns">
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>{parse(text.working8)}</GroupuiHeadline>
                            <br/>
                            <GroupuiAccordion first={true} expanded={true} size="l">
                                <span slot="headline"><GroupuiHeadline heading="h6">{parse(text.working9)}</GroupuiHeadline></span>
                                <GroupuiText>{parse(text.working10)}</GroupuiText>
                            </GroupuiAccordion>
                            <GroupuiAccordion size="l">
                                <span slot="headline"><GroupuiHeadline heading="h6">{parse(text.working11)}</GroupuiHeadline></span>
                                <GroupuiText>{parse(text.working12)}</GroupuiText>
                            </GroupuiAccordion>
                            <GroupuiAccordion size="l">
                                <span slot="headline"><GroupuiHeadline heading="h6">{parse(text.working13)}</GroupuiHeadline></span>
                                <GroupuiText>{parse(text.working14)}</GroupuiText>
                            </GroupuiAccordion>
                            <GroupuiAccordion size="l">
                                <span slot="headline"><GroupuiHeadline heading="h6">{parse(text.working15)}</GroupuiHeadline></span>
                                <GroupuiText>{parse(text.working16)}</GroupuiText>
                            </GroupuiAccordion>
                            <GroupuiAccordion size="l">
                                <span slot="headline"><GroupuiHeadline heading="h6">{parse(text.working17)}</GroupuiHeadline></span>
                                <GroupuiText>{parse(text.working18)}</GroupuiText>
                            </GroupuiAccordion>
                            <GroupuiAccordion size="l">
                                <span slot="headline"><GroupuiHeadline heading="h6">{parse(text.working19)}</GroupuiHeadline></span>
                                <GroupuiText>{parse(text.working20)}</GroupuiText>
                            </GroupuiAccordion>
                            <GroupuiAccordion size="l">
                                <span slot="headline"><GroupuiHeadline heading="h6">{parse(text.working21)}</GroupuiHeadline></span>
                                <GroupuiText>{parse(text.working22)}</GroupuiText>
                            </GroupuiAccordion>
                            <GroupuiAccordion size="l">
                                <span slot="headline"><GroupuiHeadline heading="h6">{parse(text.working23)}</GroupuiHeadline></span>
                                <GroupuiText>{parse(text.working24)}</GroupuiText>
                            </GroupuiAccordion>
                            <GroupuiAccordion size="l">
                                <span slot="headline"><GroupuiHeadline heading="h6">{parse(text.working25)}</GroupuiHeadline></span>
                                <GroupuiText>{parse(text.working26)}</GroupuiText>
                            </GroupuiAccordion>
                            <GroupuiAccordion size="l">
                                <span slot="headline"><GroupuiHeadline heading="h6">{parse(text.working27)}</GroupuiHeadline></span>
                                <GroupuiText>{parse(text.working28)}</GroupuiText>
                            </GroupuiAccordion>
                            <GroupuiAccordion size="l">
                                <span slot="headline"><GroupuiHeadline heading="h6">{parse(text.working29)}</GroupuiHeadline></span>
                                <GroupuiText>{parse(text.working30)}</GroupuiText>
                            </GroupuiAccordion>
                            <br/>
                            <a href="https://careers.volkswagen-me.info/index.php/job-listings" target="_blank" rel="noreferrer" className="cta">{parse(text.working6)}</a>
                        </div>
                    </div>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser">
                <div className="ui-inner">
                    <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>{parse(text.working32)}</GroupuiHeadline>
                    <div className="ui-columns">
                        <div className="ui-column">
                            <GroupuiText>{parse(text.working33)}</GroupuiText>
                        </div>
                    </div>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser ui-deep-blue-bg">
                <div className="ui-inner">
                    <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>{parse(text.working34)}</GroupuiHeadline>
                    <div className="ui-columns">
                        <div className="ui-column">
                            <GroupuiText>{parse(text.working35)}</GroupuiText>
                        </div>
                    </div>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser">
                <div className="ui-inner">
                    <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>{parse(text.working36)}</GroupuiHeadline>
                    <div className="ui-columns">
                        <div className="ui-column">
                            <GroupuiText>{parse(text.working37)}</GroupuiText>
                        </div>
                    </div>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser ui-deep-blue-bg">
                <div className="ui-inner">
                    <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>{parse(text.working38)}</GroupuiHeadline>
                    <div className="ui-columns">
                        <div className="ui-column">
                            <GroupuiText>{parse(text.working39)}</GroupuiText>
                        </div>
                    </div>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser">
                <div className="ui-inner">
                    <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>{parse(text.working40)}</GroupuiHeadline>
                    <div className="ui-columns">
                        <div className="ui-column">
                            <GroupuiText>{parse(text.working41)}</GroupuiText>
                        </div>
                    </div>
                </div>
            </div>
		</div>
	);
}

import { GroupuiHeadline, GroupuiText } from '@group-ui/group-ui-react';
import useText from '../../hooks/useText';
import parse from 'html-react-parser';
export default function Page(){
    const text = useText();
	return (
		<div className="page">
            {/* banner */}
            <div className="ui-banner">
                <div className="media">
                    <video src={`${process.env.PUBLIC_URL}/assets/video/VWG_Layers_green_blue.mp4`} autoPlay muted loop playsInline/>
                </div>
                <div className="ui-inner">
                    <GroupuiHeadline heading="h2" fontVariant="TheGroupHEAD-Light" marketing={true}>{parse(text.whistleblower1)}</GroupuiHeadline>
                    <GroupuiText>{parse(text.whistleblower2)}</GroupuiText>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser">
                <div className="ui-inner">
                    <div className="ui-columns">
                        <div className="ui-column">
                            <div className="media">
                                <img src={`${process.env.PUBLIC_URL}/assets/img/editorial/conduct.jpg`} alt=""/>
                            </div>
                        </div>
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>{parse(text.whistleblower3)}</GroupuiHeadline>
                            <GroupuiText>{parse(text.whistleblower4)}</GroupuiText>
                        </div>
                    </div>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser ui-deep-blue-bg">
                <div className="ui-inner">
                    <div className="ui-columns">
                        <div className="ui-column">
                            <div className="media">
                                <img src={`${process.env.PUBLIC_URL}/assets/img/compliance/whistleblower-chart.png`} alt=""/>
                            </div>
                            <br/><br/>
                            <GroupuiText>{parse(text.whistleblower5)}</GroupuiText>
                        </div>
                    </div>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser">
                <div className="ui-inner">
                    <div className="ui-columns">
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>{parse(text.whistleblower6)}</GroupuiHeadline>
                            <GroupuiText>
                                {parse(text.whistleblower7)}
                            </GroupuiText>
                        </div>
                    </div>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser ui-deep-blue-bg">
                <div className="ui-inner">
                    <div className="ui-columns">
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>{parse(text.whistleblower8)}</GroupuiHeadline>
                            <GroupuiText>
                            {parse(text.whistleblower9)}
                            </GroupuiText>
                            <br/><br/>
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>{parse(text.whistleblower10)}</GroupuiHeadline>
                            <GroupuiText>
                                {parse(text.whistleblower11)}
                                <br/><br/>
                                <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>{parse(text.whistleblower12)}</GroupuiHeadline>
                                <GroupuiText>{parse(text.whistleblower13)}</GroupuiText>
                                <a href={`${process.env.PUBLIC_URL}/assets/docs/whistleblowers-2024-dual.pdf`} className="cta">{parse(text.whistleblower14)}</a>
                            </GroupuiText>
                        </div>
                    </div>
                </div>
            </div>
		</div>
	);
}

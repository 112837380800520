import { useState, useEffect } from 'react';
import useBreaks from '../../hooks/useBreaks';
import useText from '../../hooks/useText';
import { Input, Select, Textarea, Checkbox, Text } from '@chakra-ui/react';
import { GroupuiHeadline, GroupuiText } from '@group-ui/group-ui-react';
import parse from 'html-react-parser';

// plugins
import axios from 'axios';
import isEmail from 'email-validator';
import { scroller, Element } from 'react-scroll';

export default function Page(){
    const text = useText();
    const mq = useBreaks();

    const scrollForm = () => {
        scroller.scrollTo('form', {
            duration: 800,
            smooth: true,
        });
    }

    const [ready,setReady] = useState(false);
    const [thanks,setThanks] = useState(false);
    const [processing,setProcessing] = useState(false);
    const [form,setForm] = useState({
        name:'',
        surname:'',
        email:'',
        phone:'',
        type:'',
        message:'',
        location:'',
        gdpr:'false'
    });

    const onChange = (e) => {
        const { name, value, checked } = e.target;
        if(name === 'gdpr'){
            setForm(f => ({
                ...f,
                gdpr:checked ? 'true':'false'
            }));
        }else {
            setForm(f => ({
                ...f,
                [name]:value
            }));
        }
    }

    useEffect(() => {
        const empty = Object.entries(form).find(item => item[0] !== 'location' && item[1] === '') ? true:false;
        const email = form.email !== '' && isEmail.validate(form.email);
        const phone = !isNaN(form.phone);
        const gdpr = form.gdpr === 'true';
        setReady(!empty && email && phone && gdpr);
    }, [form])
    

    const onSubmit = (e) => {
        if(ready){
            e.preventDefault();
            e.stopPropagation();
            setProcessing(true);
            axios({
		        method: 'post',
		        responseType: 'json',
		        url: 'https://rest.audimiddleeast.com/group/fleet.php',
		        data: {
                    name:form.name,
                    surname:form.surname,
                    email:form.email,
                    phone:form.phone,
                    type:form.type,
                    message:form.message,
                    location:form.location,
                    gdpr:form.gdpr
			  	},
			  	headers: {
			  		'authorization':'7e44fd4bf570f256ce66fdd2f3a5d13PRJT'
			  	}
		    }).then((res)=>{
		    	if(res.data === 'success'){
                    setThanks(true);
                    setTimeout(()=>{
                        setProcessing(false);
                    },250);
		    	}else {
		    		alert("Somethings not right: please try again later");
                    setProcessing(false);
		    	}
		    }).catch((err)=>{
                console.log(err)
		    	alert("Somethings not right: please try again later");
                setProcessing(false);
		    }) 
        }
    }

	return (
        <div className="page">
            {/* banner */}
            <div className="ui-banner">
                <div className="media">
                    <video src={`${process.env.PUBLIC_URL}/assets/video/VWG_Layers_green_blue.mp4`} autoPlay muted loop playsInline/>
                </div>
                <div className="ui-inner">
                    <GroupuiHeadline heading="h2" fontVariant="TheGroupHEAD-Light" marketing={true}>{parse(text.fleet_banner_headline)}</GroupuiHeadline>
                    <GroupuiText>{parse(text.fleet_banner_text)}</GroupuiText>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser">
                <div className="ui-inner">
                    <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>{text.fleet_solutions_headline}</GroupuiHeadline>
                    <div className="ui-columns">
                        <div className="ui-column">
                            <div className="media">
                                <img src={`${process.env.PUBLIC_URL}/assets/img/editorial/fleet.jpg`} alt=""/>
                            </div>
                        </div>
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>{text.mobility_solutions_headline}</GroupuiHeadline>
                            <GroupuiText>{parse(text.mobility_solutions_text)}</GroupuiText>
                            <button className="cta" onClick={scrollForm}>{text.send_enquiry_cta}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ui-content-teaser ui-deep-blue-bg">
                <div className="ui-inner">
                    <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>{text.customized_solutions_headline}</GroupuiHeadline>
                    <div className="ui-columns">
                        <div className="ui-column">
                            <GroupuiText>{parse(text.customized_solutions_text)}</GroupuiText>
                        </div>
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>{text.regional_brands_headline}</GroupuiHeadline>
                            <div className="ui-logos">
                                <a href="https://www.volkswagen-me.com/en/fleet.html" className="ui-logo">
                                    <img src={`${process.env.PUBLIC_URL}/assets/img/logo/box-vw.jpg`} alt=""/>
                                </a>
                                <a href="https://www.audi-me.com/me/web/meen/fleet.html#Make-an-enquiry" className="ui-logo">
                                    <img src={`${process.env.PUBLIC_URL}/assets/img/logo/box-audi.jpg`} alt=""/>
                                </a>
                                <a href="https://www.skoda-me.com/company/fleet" className="ui-logo">
                                    <img src={`${process.env.PUBLIC_URL}/assets/img/logo/box-skoda.jpg`} alt=""/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Element name="form"></Element>
            {
            !thanks ?
            <div className="ui-form">
                <div className="ui-inner">
                    <GroupuiHeadline heading="h2" fontVariant="TheGroupHEAD-Bold" marketing={false}>{text.get_in_touch_headline}</GroupuiHeadline>
                    <GroupuiText>{parse(text.get_in_touch_text)}</GroupuiText>
                    <form>
                        <div className="ui-form-row">
                            <div className="ui-form-column">
                                <div className="ui-form-element">
                                    <Text fontSize="sm" fontWeight="bold" mb="1">{text.first_name_label}</Text>
                                    <Input name="name" borderColor="#a8adb3" required value={form.name} onChange={onChange}/>
                                </div>
                            </div>
                            <div className="ui-form-column">
                                <div className="ui-form-element">
                                    <Text fontSize="sm" fontWeight="bold" mb="1">{text.last_name_label}</Text>
                                    <Input name="surname" borderColor="#a8adb3" required value={form.surname} onChange={onChange}/>
                                </div>
                            </div>
                        </div>
                        <div className="ui-form-row">
                            <div className="ui-form-column">
                                <div className="ui-form-element">
                                    <Text fontSize="sm" fontWeight="bold" mb="1">{text.email_label}</Text>
                                    <Input name="email" type="email" borderColor="#a8adb3" required value={form.email} isInvalid={form.email !== '' && !isEmail.validate(form.email)} onChange={onChange}/>
                                </div>
                            </div>
                            <div className="ui-form-column">
                                <div className="ui-form-element">
                                    <Text fontSize="sm" fontWeight="bold" mb="1">{text.phone_label}</Text>
                                    <Input name="phone" type="tel" pattern="[0-9.]*" borderColor="#a8adb3" required value={form.phone} isInvalid={isNaN(form.phone)} onChange={onChange}/>
                                </div>
                            </div>
                        </div>
                        <div className="ui-form-row">
                            <div className="ui-form-column">
                                <div className="ui-form-element">
                                    <Text fontSize="sm" fontWeight="bold" mb="1">{text.fleet_type_label}</Text>
                                    <Select name="type" borderColor="#a8adb3" required value={form.type} onChange={onChange}>
                                        <option value="" disabled></option>
                                        <option value="general">{text.general_fleet_option}</option>    
                                        <option value="vw">{text.vw_fleet_option}</option>    
                                        <option value="audi">{text.audi_fleet_option}</option>    
                                        <option value="skoda">{text.skoda_fleet_option}</option>        
                                    </Select>
                                </div>
                            </div>
                            <div className="ui-form-column spacer"></div>
                        </div>
                        <div className="ui-form-row">
                            <div className="ui-form-column grow">
                                <div className="ui-form-element">
                                    <Text fontSize="sm" fontWeight="bold" mb="1">{text.message_label}</Text>
                                    <Textarea name="message" borderColor="#a8adb3" resize="none" rows={mq.tablet ? 5:8} required value={form.message} onChange={onChange}/>
                                </div>
                            </div>
                        </div>
                        <div className="ui-form-row" hidden>
                            <div className="ui-form-column grow">
                                <div className="ui-form-element">
                                    <Text fontSize="sm" fontWeight="bold" mb="1">{text.location_label}</Text>
                                    <Input name="location" borderColor="#a8adb3" value={form.location} onChange={onChange}/>
                                </div>
                            </div>
                        </div>
                        <div className="ui-form-row">
                            <div className="ui-form-column grow">
                                <Checkbox name="gdpr" required value={form.gdpr} onChange={onChange}>
                                    {parse(text.gdpr_checkbox)}
                                </Checkbox>
                            </div>
                        </div>
                        <div className="ui-form-submit">
                            <button type="submit" className="cta" disabled={!ready} onClick={onSubmit}>{processing ? text.processing_button : text.send_request_button}</button>
                        </div>
                        {
                            !ready && <Text fontSize="xs" color="#db0034" fontWeight="bold">{text.form_error_message}</Text>
                        }
                        <div className="ui-disclaimer">
                            <GroupuiText size="caption">{text.disclaimer_text}</GroupuiText>
                        </div>
                    </form>
                </div>
            </div>
            :
            <div className="ui-thanks">
                <div className="ui-inner">
                    <GroupuiHeadline heading="h2" fontVariant="TheGroupHEAD-Bold" marketing={false}>{text.thank_you_headline}</GroupuiHeadline>
                    <br/>
                    <GroupuiText>{parse(text.thank_you_text)}</GroupuiText>
                </div>
            </div>
            }
        </div>
    );
}

import { GroupuiHeadline, GroupuiText } from "@group-ui/group-ui-react";
import useArabic from "../hooks/useArabic";
import useText from "../hooks/useText";
import news from "../json/news.json";

export default function Page() {
	const arabic = useArabic();
    const text = useText();
	return (
		<div className="page">
			<div className="ui-banner">
				<div className="media">
					<video
						src={`${process.env.PUBLIC_URL}/assets/video/VWG_Wave_blue.mp4`}
						autoPlay
						muted
						loop
						playsInline
					/>
				</div>
				<div className="ui-inner">
					<GroupuiHeadline
						heading="h2"
						fontVariant="TheGroupHEAD-Light"
						marketing={true}
					>
						<span className="ui-neon-text">
							{text.newsroom_title}
						</span>
					</GroupuiHeadline>
					<GroupuiText>{text.newsroom_subtitle}</GroupuiText>
				</div>
			</div>
			<div className={`ui-content-teaser no-margin`}>
				<div className="ui-inner">
					<GroupuiHeadline
						heading="h1"
						fontVariant="TheGroupHEAD-Light"
						marketing={false}
					>
						{text.news_explore_headline}
					</GroupuiHeadline>
				</div>
			</div>
			{news.map((item, k) => (
				<div key={k} className={`ui-content-teaser no-gap-top`}>
					<div className="ui-inner">
						<div className="ui-columns">
							<div className="ui-column">
								<div className="media five-three">
									<img
										src={`${process.env.PUBLIC_URL}${item.img.thumb}`}
										alt=""
									/>
								</div>
							</div>
							<div className="ui-column">
								<div className="ui-chip">
									{item.brand === 'Global' ? text.global_news:text.regional_news} 
								</div>
								<GroupuiHeadline
									heading="h3"
									fontVariant="TheGroupHEAD-Bold"
									marketing={false}
								>
									{item.title[arabic ? 'ar':'en']}
								</GroupuiHeadline>
								<GroupuiText>{item.lead[arabic ? 'ar':'en']}</GroupuiText>
								<div className="btns">
									<a
										href={item.cta[0].link}
										target="_blank"
										rel="noreferrer"
										className="cta"
									>
										{text.read_article_cta}
									</a>
									<a
										href={item.cta[1].link}
										target="_blank"
										rel="noreferrer"
										className="cta"
									>
										{text.all_articles_cta}
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			))}
		</div>
	);
}

import { useState, useEffect } from 'react';
import { GroupuiHeadline, GroupuiText, GroupuiSelect, GroupuiSelectOption } from '@group-ui/group-ui-react';
import { scroller, Element } from 'react-scroll';
import useArabic from '../hooks/useArabic';
import useText from '../hooks/useText';

import brands from '../json/brands.json';

export default function Page(){

    const arabic = useArabic();
    const text = useText();
    const list = Object.values(brands);

    const [active,setActive] = useState(0);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        setTimeout(()=>{
            
        
        if(params.get('scroll') === 'audi'){
            scroller.scrollTo('audi', {
                duration: 800,
                smooth: true,
                offset:-50
            });
        }
        if(params.get('scroll') === 'vw'){
            scroller.scrollTo('vw', {
                duration: 800,
                smooth: true,
                offset:-50
            });
        }
        if(params.get('scroll') === 'skoda'){
            scroller.scrollTo('skoda', {
                duration: 800,
                smooth: true,
                offset:-50
            });
        }
        if(params.get('scroll') === 'bentley'){
            scroller.scrollTo('bentley', {
                duration: 800,
                smooth: true,
                offset:-50
            });
        }
        if(params.get('scroll') === 'lamborghini'){
            scroller.scrollTo('lamborghini', {
                duration: 800,
                smooth: true,
                offset:-50
            });
        }

        },500);
    }, [])
    

	return (
		<div className="page">
            <div className="ui-banner">
                <div className="media">
                    <video src={`${process.env.PUBLIC_URL}/assets/video/VWG_Ascent_darkblue.mp4`} autoPlay muted loop playsInline/>
                </div>
                <div className="ui-inner">
                    <GroupuiHeadline heading="h2" fontVariant="TheGroupHEAD-Light" marketing={true}>{text.our_brands}</GroupuiHeadline>
                    <GroupuiText>{text.brands_common_goal}</GroupuiText>
                </div>
            </div>

            <div className={`ui-content-teaser no-margin`}>
                <div className="ui-inner">
                    <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>{text.explore_group_brands}</GroupuiHeadline>
                </div>
            </div>
            
            {
            list.map((brand,k) => (
                <div key={k}>
                <Element name={brand.id} id={brand.id}></Element>
                <div className={`ui-content-teaser no-gap-top ${active === k ? 'above':''}`}>
                    <div className="ui-inner">
                        <div className="ui-columns">
                            <div className="ui-column">
                                <div className="media">
                                    <img src={`${process.env.PUBLIC_URL}${brand.img.thumb}`} alt=""/>
                                </div>
                            </div>
                            <div className="ui-column">
                                <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>{brand.title}</GroupuiHeadline>
                                <GroupuiText>{brand.lead[arabic ? 'ar':'en']}</GroupuiText>
                                {
                                brand.cta.length > 1 ?
                                    <GroupuiSelect onGroupuiChange={(e) => window.open(e.target.value)} onClick={() => setActive(k)}>
                                        {
                                        brand.cta.map((link,k) => (
                                            <GroupuiSelectOption key={k} value={link.link}>{link.text[arabic ? 'ar':'en']}</GroupuiSelectOption>    
                                        ))
                                        }
                                        <span slot="label">{text.brand_websites}</span>
                                        <span slot="description">{text.select_regional_website}</span>
                                    </GroupuiSelect>
                                :
                                    <a href={brand.cta[0].link} target="_blank" rel="noreferrer" className="cta">{text.brand_website}</a>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            ))
            }

        </div>
	);
}
import { GroupuiHeadline, GroupuiText } from '@group-ui/group-ui-react';
import useArabic from '../../hooks/useArabic';
import useText from '../../hooks/useText';
import parse from 'html-react-parser';

export default function Page(){
    const arabic = useArabic();
    const text = useText();
	return (
		<div className="page">
            {/* banner */}
            <div className="ui-banner">
                <div className="media">
                    <img src={`${process.env.PUBLIC_URL}/assets/img/group/banner/together.jpg`} alt=""/>
                </div>
                <div className="ui-inner">
                    <GroupuiHeadline heading="h2" fontVariant="TheGroupHEAD-Light" marketing={true}>{parse(text.human_banner_headline)}</GroupuiHeadline>
                    <GroupuiText>{text.human_banner_text}</GroupuiText>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser">
                <div className="ui-inner">
                    <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>{text.human_rights_headline}</GroupuiHeadline>
                    <div className="ui-columns">
                        <div className="ui-column">
                            <div className="media">
                                <img src={`${process.env.PUBLIC_URL}/assets/img/careers/lead.jpg`} alt=""/>
                            </div>
                        </div>
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>{text.our_commitments_headline}</GroupuiHeadline>
                            <GroupuiText>{text.our_commitments_text}</GroupuiText>
                        </div>
                    </div>
                    <br/>
                    <div className="ui-columns">
                        <div className="ui-column">
                            <GroupuiText>{parse(text.international_agreements_text)}</GroupuiText>
                        </div>
                    </div>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser ui-deep-blue-bg">
                <div className="ui-inner">
                    <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>{text.protecting_human_rights_headline}</GroupuiHeadline>
                    <div className="ui-columns">
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>{text.responsibilities_quote}</GroupuiHeadline>
                            <GroupuiText>{text.responsibilities_text_1}</GroupuiText>
                        </div>
                        <div className="ui-column">
                            <GroupuiText>{parse(text.responsibilities_text_2)}</GroupuiText>
                            <a href={`/${arabic ? 'ar':'en'}/group/whistleblower`} className="cta">{text.whistleblowers_cta}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ui-content-teaser">
                <div className="ui-inner">
                    <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>{text.declaration_social_rights_headline}</GroupuiHeadline>
                    <div className="ui-columns">
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>{text.hints_seriousness_headline}</GroupuiHeadline>
                            <GroupuiText>{parse(text.hints_seriousness_text)}</GroupuiText>
                            <div className="btns">
                                <a href="https://www.volkswagen-group.com/en/publications/more/declaration-on-social-rights-1869" target="_blank" rel="noreferrer" className="cta">{text.download_cta}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</div>
	);
}
import { GroupuiHeadline, GroupuiText } from '@group-ui/group-ui-react';
import useArabic from '../../hooks/useArabic';
import useText from '../../hooks/useText';

export default function Page(){
    const arabic = useArabic();
    const text = useText();
	return (
		<div className="page">
            {/* banner */}
            <div className="ui-banner">
                <div className="media">
                    <video src={`${process.env.PUBLIC_URL}/assets/video/VWG_Mountains_green_blue.mp4`} autoPlay muted loop playsInline/>
                </div>
                <div className="ui-inner">
                    <GroupuiHeadline heading="h2" fontVariant="TheGroupHEAD-Light" marketing={true}>{text.banner_headline} <br/><span className="ui-neon-text">{text.banner_subheadline}</span></GroupuiHeadline>
                    <GroupuiText>{text.banner_text}</GroupuiText>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser">
                <div className="ui-inner">
                    <div className="ui-columns">
                        <div className="ui-column">
                            <div className="media">
                                <img src={`${process.env.PUBLIC_URL}/assets/img/editorial/about.jpg`} alt=""/>
                            </div>
                        </div>
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>{text.about_us_headline}</GroupuiHeadline>
                            <GroupuiText>{text.about_us_text}</GroupuiText>
                            <a href={`/${arabic ? 'ar':'en'}/group/team`} className="cta">{text.discover_team_cta}</a>
                        </div>
                    </div>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser ui-deep-blue-bg">
                <div className="ui-inner">
                    <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>{text.driving_innovation_headline}</GroupuiHeadline>
                    <div className="ui-columns">
                        <div className="ui-column">
                            <GroupuiText>{text.driving_innovation_text}</GroupuiText>
                        </div>
                    </div>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser">
                <div className="ui-inner">
                    <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>{text.journey_middle_east_headline}</GroupuiHeadline>
                    <div className="ui-columns">
                        <div className="ui-column">
                            <GroupuiHeadline heading="h2" fontVariant="TheGroupHEAD-Bold" marketing={false}>{text.journey_middle_east_subheadline}</GroupuiHeadline>
                            <GroupuiText>{text.journey_middle_east_text}</GroupuiText>
                        </div>
                    </div>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser seamless center spaced">
                <div className="ui-inner">
                    <div className="ui-columns">
                        <div className="ui-column">
                            <div className="media eight-five">
                                <img src={`${process.env.PUBLIC_URL}/assets/img/journey/1.jpg`} alt=""/>
                            </div>
                        </div>
                        <div className="ui-column">
                            <GroupuiHeadline heading="h4" fontVariant="TheGroupHEAD-Bold" marketing={false}>{text.headquarters_info}</GroupuiHeadline>
                        </div>
                    </div>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser seamless center spaced">
                <div className="ui-inner">
                    <div className="ui-columns">
                        <div className="ui-column">
                            <GroupuiHeadline heading="h4" fontVariant="TheGroupHEAD-Bold" marketing={false}>{text.dubai_office_info}</GroupuiHeadline>
                        </div>
                        <div className="ui-column">
                            <div className="media eight-five">
                                <img src={`${process.env.PUBLIC_URL}/assets/img/journey/2.jpg`} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser seamless center spaced">
                <div className="ui-inner">
                    <div className="ui-columns">
                        <div className="ui-column">
                            <div className="media eight-five">
                                <img src={`${process.env.PUBLIC_URL}/assets/img/journey/3.jpg`} alt=""/>
                            </div>
                        </div>
                        <div className="ui-column">
                            <GroupuiHeadline heading="h4" fontVariant="TheGroupHEAD-Bold" marketing={false}>{text.training_academy_info}</GroupuiHeadline>
                        </div>
                    </div>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser seamless center spaced">
                <div className="ui-inner">
                    <div className="ui-columns">
                        <div className="ui-column">
                            <GroupuiHeadline heading="h4" fontVariant="TheGroupHEAD-Bold" marketing={false}>{text.parts_operations_info}</GroupuiHeadline>
                        </div>
                        <div className="ui-column">
                            <div className="media eight-five">
                                <img src={`${process.env.PUBLIC_URL}/assets/img/journey/4.jpg`} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</div>
	);
}
import { GroupuiHeadline, GroupuiText } from '@group-ui/group-ui-react';
import useArabic from '../../hooks/useArabic';
import useText from '../../hooks/useText';
import parse from 'html-react-parser';

export default function Page(){
    const arabic = useArabic();
    const text = useText();
	return (    
		<div className="page">
            {/* banner */}
            <div className="ui-banner">
                <div className="media">
                    <video src={`${process.env.PUBLIC_URL}/assets/video/VWG_Layers_green_blue.mp4`} autoPlay muted loop playsInline/>
                </div>
                <div className="ui-inner">
                    <GroupuiHeadline heading="h2" fontVariant="TheGroupHEAD-Light" marketing={true}>{parse(text.strategy_banner_headline)}</GroupuiHeadline>
                    <GroupuiText>{parse(text.strategy_banner_text)}</GroupuiText>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser">
                <div className="ui-inner">
                    <div className="ui-columns">
                        <div className="ui-column" style={{textAlign:'center'}}>
                            <GroupuiHeadline heading="h2" fontVariant="TheGroupHEAD-Light" marketing={true}>{text.strategy_headline}</GroupuiHeadline>
                            <GroupuiText>{parse(text.strategy_text)}</GroupuiText>
                            <a href="https://www.volkswagen-group.com/en/strategy-15955" target="_blank" rel="noreferrer" className="cta">{text.discover_more_cta}</a>
                            <br/><br/><br/>
                            <div className="media">
                                <video src={`${process.env.PUBLIC_URL}/assets/video/strategy.mp4`} controls autoPlay muted playsInline loop/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser ui-deep-blue-bg">
                <div className="ui-inner">
                    <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>{text.evolving_mobility_headline}</GroupuiHeadline>
                    <div className="ui-columns">
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>{text.vision_2030_headline}</GroupuiHeadline>
                            <GroupuiText>{parse(text.vision_2030_text)}</GroupuiText>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ui-content-teaser">
                <div className="ui-inner">
                    <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>{text.about_us_headline}</GroupuiHeadline>
                    <div className="ui-columns">
                        <div className="ui-column">
                            <div className="media">
                                <img src={`${process.env.PUBLIC_URL}/assets/img/editorial/about.jpg`} alt=""/>
                            </div>
                        </div>
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>{text.discover_innovation_headline}</GroupuiHeadline>
                            <GroupuiText>{parse(text.about_us_fulltext)}</GroupuiText>
                            <a href={`/${arabic ? 'ar':'en'}/group/about`} className="cta">{text.learn_more_cta}</a>
                        </div>
                    </div>
                </div>
            </div>
		</div>
	);
}